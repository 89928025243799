import React from "react";
import Img from "gatsby-image"
import { graphql } from 'gatsby';
import Layout from "../layouts/layout";
import Video from "../components/video";
import Modal from 'react-modal';
import Timeline from "../components/timeline";


const WhoWeAre = ({data: {pageData, hero, founder}}) =>  {

        const customStyles = {
            overlay: {
                      position: 'fixed',
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      backgroundColor: 'rgba(255, 255, 255, 0.35)',
                      zIndex: 1000
                    },
              content : {
                      top                   : '50%',
                      left                  : '50%',
                      right                 : 'auto',
                      bottom                : 'auto',
                      marginRight           : '-50%',
                      transform             : 'translate(-50%, -50%)',
                      backgroundColor: '#F3F3F3'
                    }
        };

      const [modalIsOpen,setIsOpen] = React.useState(false);

      const [currentDirectorCount, setCurrentDirectorCount] = React.useState(0); 

      function openModal(director, i) {
              setCurrentDirectorCount(i);
              setIsOpen(true);
            }

     function afterOpenModal() {
     }

      function closeModal(){
              setIsOpen(false);
            }

return(
<Layout hero={hero}>
    <div className="container mx-auto mb-16" id="the-group">
        <div className="md:flex items-center justify-between items-stretch">
            <div className="md:w-1/2 px-4 mb-4 md:pr-12 md:mb-0">
                <Img className="object-cover object-center w-full h-full block" fluid={pageData.promoImage.fluid} />
            </div>
            <div className="md:w-1/2 px-4">
                <div className="mb-6">
                    <h2 className="promo-heading">{pageData.promoHeadingOne}</h2>
                </div>
                <div className="soft-gray leading-relaxed nested-html"
                     dangerouslySetInnerHTML={{ __html: pageData.promoOneText.childMarkdownRemark.html }}
                />                    

                <div className="sm:flex mt-12">
                    <div className="flex-1 text-center">
                        <h5 className="text-2-5 font-aktiv dark-blue font-bold">1971</h5>
                        <p className="soft-gray text-sm">Man Enterprise founded</p>
                    </div>
                    <div className="flex-1 text-center">
                        <h5 className="text-2-5 font-aktiv dark-blue font-bold">12</h5>
                        <p className="soft-gray text-sm">Geographical regions</p>
                    </div>
                    <div className="flex-1 text-center">
                        <h5 className="text-2-5 font-aktiv dark-blue font-bold">8,000</h5>
                        <p className="soft-gray text-sm">Employees</p>
                    </div>
                    <div className="flex-1 text-center">
                        <h5 className="text-2-5 font-aktiv dark-blue font-bold">5</h5>
                        <p className="soft-gray text-sm">Subsidiaries</p>
                    </div>
                </div>

            <div id="vision-mission" />
            </div>
        </div>
    </div>

    <div style={{backgroundColor: "#f5f5f5"}}>
        <div className="px-4 py-16 container mx-auto mb-16 flex border-t border-b">

            <div className="flex-1 mr-4 border-r-2">
                <h3 className="font-bold text-gray-800 text-2xl mb-4">Our Vision</h3>
                <p>{pageData.visionStatement}</p>
            </div>

            <div className="flex-1 pl-4">
                <h3 className="font-bold text-gray-800 text-2xl mb-4">Our Mission</h3>
                <p>{pageData.missionStatement}</p>
            </div>

        </div>
    </div>

    {/* Our Values */}
    <div className="container mx-auto mb-16">
        <div className="commitment-anchor" id="our-core-values" />
        <h2 className="promo-heading px-4 mb-8">Our Core Values</h2>

        <div className="md:flex items-center justify-between mb-16">

            <div className="flex-1 px-4 mb-4">
                    <div className="soft-gray leading-relaxed nested-html"
                        dangerouslySetInnerHTML={{ __html: pageData.valuesContent.childMarkdownRemark.html }}
                    />                    
            </div>

            <div className="flex-1 px-4 sm:mb-0">
                    <Img className="object-cover object-center w-4/5 h-full block mx-auto" fluid={pageData.valuesImage.fluid} />
            </div>
                   
        </div>
    </div>


    <div className="container mx-auto px-4 mb-16">
        <Video url={pageData.promoVideo} />
    </div>

    <div className="bg-gray border-t border-b py-8 mb-16" id="heritage-history">
        <div className="container mx-auto mb-16 p-8 px-4 overflow-x-hidden">
            <div className="sm:flex justify-between">

                <div className="flex-1 order-first">
                    <div className="mb-6">
                        <h2 className="text-4xl sm:text-5xl font-bold dark-blue leading-tight">Our Heritage &amp; History</h2>
                    </div>
                    <p className="soft-gray sm:mb-4 md:mb-0 sm:w-3/5">Established in 1971, MAN Enterprise progressed to become an international leader in the construction industry. We have built our reputation through delivering turnkey projects to a broad base of both public and private sector clients. To date, our expertise has been implemented in a wide range of large-scale developments in the sectors of infrastructure, recreation, hospitality, education, residential, commercial and industrial.
                    </p>

                    <Timeline />

                </div>
                
           </div>

        </div>
    </div>

    <div className="container mx-auto mb-16 px-4" id="our-people">
        <div className="mb-6">
            <h2 className="text-4xl sm:text-5xl font-bold dark-blue leading-tight mb-8">Leadership</h2>
            <h2 className="text-xl sm:text-xl font-bold dark-blue leading-tight">The Founder</h2>
        </div>

        <div className="lg:flex justify-between border-shadow">

            <div className="lg:w-1/2 lg:pr-4">
    {/* <Img className="object-cover object-center w-full h-full block" fluid={founder.fluid} /> */}
            </div>

            <div className="flex-1 sm:ml-8 p-4 sm:mt-2">
                <p className="font-bold font-aktiv dark-blue text-2xl mt-2 mb-4">1946 - 2019</p>
                <p className="mt-6 font-aktiv font-bold text-xl soft-gray">Michel Abi Nader</p>
                <small className="font-aktiv dark-blue font-bold">FOUNDER</small>
                <p className="mt-6 nested-html soft-gray bio-nested-html" dangerouslySetInnerHTML={{ __html: pageData.founderBio.childMarkdownRemark.html}} />
            </div>

        </div>

    </div>

    <div className="container mx-auto mb-16">
        <h2 className="text-xl sm:text-xl px-4 font-bold dark-blue leading-tight">Board of Directors</h2>
    
                <div className="flex flex-wrap" id="directors">

                    {pageData.boardOfDirectors.map((director, i) => 
                        <div style={{height:"auto"}} className="mb-24 p-4 relative w-1/2 lg:w-1/3 xl:w-1/4" key={i}>
                                <a onClick={() => openModal(director, i)} role="button">
                        {/*
                                    <Img className="h-full w-full object-center object-cover"
                                         fluid={director.picture.fluid}
                                    />
                        */}
                                </a>
                                <div className="p-4 shadow-md">
                                    <p className="soft-gray font-bold">{director.name}</p>
                                    <p className="text-sm soft-gray">{director.position}</p>
                                    <p className="text-sm soft-gray">{director.company}</p>
                                </div>
                            </div>
                    )}
                </div>

                   <Modal
                        isOpen={modalIsOpen}
                        onAfterOpen={afterOpenModal}
                        onRequestClose={closeModal}
                        style={customStyles}
                        contentLabel={pageData.boardOfDirectors[currentDirectorCount].name}
                        ariaHideApp={false}
                        >
                        <div onClick={closeModal} className="lg:flex">
                            <div className="hidden sm:blockpr-4 w-full xl:w-2/6" style={{height:"auto"}}>
                        {/*
                                    <Img className="h-full w-full object-center object-cover"
                                         fluid={pageData.boardOfDirectors[currentDirectorCount].picture.fluid}
                                    />
                        */}

                            </div>
                            <div className="w-full xl:w-4/6 ">
                                <p className="ml-2 text-xl sm:text-2xl font-bold">{pageData.boardOfDirectors[currentDirectorCount].name}</p>
                                <p className="ml-2 font-aktivcd uppercase mb-4 dark-blue font-bold">{pageData.boardOfDirectors[currentDirectorCount].position}</p>
                                <div className="ml-2 text-sm sm:text-base nested-html bio-nested-html" dangerouslySetInnerHTML={{ __html: pageData.boardOfDirectors[currentDirectorCount].bio.childMarkdownRemark.html}} />
                            </div>
                        </div>
                    </Modal>

    </div>


    <div className="container mx-auto mb-16">
        <h2 className="text-xl sm:text-xl font-bold dark-blue leading-tight px-4">Senior Leadership</h2>
    
                <div className="mt-4 flex flex-wrap" id="seniorLeadership">
                    {pageData.seniorLeadership.map((director, i) => 
                        <div style={{height:"auto"}} className="mb-24 p-4 relative w-1/2 lg:w-1/3 xl:w-1/4" key={i}>
                                <a onClick={() =>{} } role="button">
                        {/*
                                    <Img className="h-full w-full object-center object-cover"
                                         fluid={director.picture.fluid}
                                    />
                        */}
                                </a>

                                <div className="p-4 shadow-md">
                                    <p className="soft-gray font-bold">{director.name}</p>
                                    <p className="text-sm soft-gray">{director.position}</p>
                                    <p className="text-sm soft-gray">{director.company}</p>
                                </div>
                            </div>
                )}
                </div>

    </div>




</Layout>
)};

export const query = graphql`
    query whoWeAre {
        hero: contentfulWhoWeArePage(slug: {eq: "who-we-are"}) {
            heroHeading {
                childMarkdownRemark {
                    html
                }
            }
            heroText {
                childMarkdownRemark {
                    html
                }
            }
            heroBackgroundImage {
                resize (width: 1440) {
                    src 
                }
            }
        }
        pageData: contentfulWhoWeArePage(slug: {eq: "who-we-are"}) {
                promoHeadingOne
                promoOneText {
                   childMarkdownRemark {
                           html
                     }
                }
                promoImage { 
                    fluid (quality: 80)  { 
                        ...GatsbyContentfulFluid_withWebp_noBase64
                    }
                }
                missionStatement
                visionStatement
                valuesContent {
                    childMarkdownRemark {
                        html
                    }
                }
                valuesImage {
                    fluid  {
                        ...GatsbyContentfulFluid_withWebp_noBase64
                      }
                }
                promoVideo
                founderBio { 
                    childMarkdownRemark {
                        html
                    }
                }
                boardOfDirectors {
                      name
                      position
                      company
                      bio {
                            childMarkdownRemark {
                              html
                          }
                     }
                     picture {
                         file {
                             url
                         }
                        fluid (maxWidth:600, quality: 60) {
                            ...GatsbyContentfulFluid_withWebp_noBase64
                        }
                    }
               }
               seniorLeadership {
                      name
                      position
                      company
                      bio {
                            childMarkdownRemark {
                              html
                          }
                     }
                     picture {
                        fluid (maxWidth:600, quality: 60) {
                            ...GatsbyContentfulFluid_withWebp_noBase64
                        }

                    }
               }
            }
      founder: contentfulAsset(title: {eq: "Michel Nader"}) {
            file {
                    url
            }
            fluid (maxHeight:600, quality:60) {
                ...GatsbyContentfulFluid_withWebp_noBase64
            }

      }
    }
`;


export default WhoWeAre;
