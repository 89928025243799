import React, { useState, useRef, useLayoutEffect } from 'react';

const Timeline = () => {

    //const timelineWidth = document.getElementById('timeline').scrollWidth;
    const timelineContainer = useRef(null);
    const nextButtonRef = useRef(null);
    const prevButtonRef = useRef(null);
    const [visibleNext, updateVisibleNext] = useState(true);
    const [visiblePrev, updateVisiblePrev] = useState(false);
    const [timelineWidth, setWidth] = useState(0);
    const [marker, updateMarker] = useState(0);
    const [markerCount, updateMarkerCount] = useState(0);

      useLayoutEffect(() => {
              setWidth((timelineContainer.current.getBoundingClientRect().width / window.devicePixelRatio));
    }, []);

    const getPrevButtonPosition = () => {
        let left = timelineContainer.current.offsetWidth * markerCount;
        if (!visibleNext) left = timelineContainer.current.scrollWidth - timelineContainer.current.offsetWidth; 
        return { left: left + "px" }
    }

    const handleNext = (e) => {

        //const stepAmount = timelineWidth;
        const stepAmount = timelineContainer.current.offsetWidth;
        const currentStyles = getComputedStyle(nextButtonRef.current);
        const currentLeft = currentStyles.left;

        const nextAmount = parseInt(currentLeft) + stepAmount;

        if (timelineContainer.current.scrollWidth - parseInt(currentLeft) > stepAmount ) {
            e.currentTarget.style.left = nextAmount + "px"
        } else {
            updateVisibleNext(false);
        }

        updateVisiblePrev(true);


        timelineContainer.current.scrollTo({left:(marker + stepAmount),behavior:"smooth"});

        //use state instead here
        if (marker < timelineContainer.current.scrollWidth) {
            let newMarker = marker + stepAmount;
            updateMarker(newMarker);
            updateMarkerCount(markerCount + 1);
        }

    }

    const handlePrev = (e) => {

        const stepAmount = timelineWidth;
        const currentStyles = getComputedStyle(prevButtonRef.current);
        const currentLeft = currentStyles.left;

        const nextAmount = parseInt(currentLeft) + stepAmount;
        
        updateMarkerCount(0);
        updateMarker(0);

        if (timelineContainer.current.scrollWidth - parseInt(currentLeft) < stepAmount ) {
            e.currentTarget.style.left = nextAmount + "px"
            updateMarker(0);

        } else {
            updateVisiblePrev(false);
        }

        timelineContainer.current.scrollTo({left:0,behavior:"smooth"});
        updateVisibleNext(true);
        resetNextButton();

    }

    const resetNextButton = () => {
        const timelineWidth = timelineContainer.current.offsetWidth;
        nextButtonRef.current.style.left = (timelineWidth * 0.9) + "px"
    }

    return (
        <div className="mt-12 pb-12 overflow-x-scroll timeline container relative" ref={timelineContainer}>
            <div className="flex ml-48 top-timeline">
                <div className="flex-none mr-48">
                    <span className="timeline-dot"></span>
                    <div className="timeline-image">
                        <img className="mb-2 w-full object-cover pt-8" src="https://lh3.googleusercontent.com/p/AF1QipPgB_k2zba2zY8PQ3L0qjn2B2TcAM6BWIzIvJoH=s1600-w800" />
                    </div>

                    <p className="font-bold">1992</p>
                    <p>Establishment of Tecman Industry</p>
                </div>
                <div className="flex-none mr-48">
                    <span className="timeline-dot"></span>
                     <div className="timeline-image">
                        <img className="mb-2 h-full w-full object-cover" src="/images/timeline/iraq.jpg" />
                    </div>
                    <p className="font-bold">2004</p>
                    <p>MAN Enterprise Iraq</p>
                </div>
                <div className="flex-none mr-48">
                    <span className="timeline-dot"></span>
                    <img className="mb-2" src="/images/timeline/metrix-timeline.png" />
                    <p className="font-bold">2006</p>
                    <p>Metrix Qatar</p>
                </div>
                <div className="flex-none mr-48">
                    <span className="timeline-dot"></span>
                    <img className="mb-2" src="/images/timeline/metrix-timeline.png" />
                    <p className="font-bold">2010</p>
                    <p>Metrix Lebanon</p>
                </div>
                <div className="flex-none mr-48">
                    <span className="timeline-dot"></span>
                    <div className="timeline-image">
                        <img className="mb-2 h-full w-full object-cover" src="/images/timeline/lti-timeline.png" />
                    </div>
                    <p className="font-bold">2015</p>
                    <p>LTI Internazionale into MAN Group</p>
                </div>
                <div className="flex-none mr-48">
                    <span className="timeline-dot"></span>
                    <div className="timeline-image">
                        <img className="mb-2 h-full w-full object-cover" src="/images/timeline/hausman-timeline.png" />
                    </div>
                    <p className="font-bold">2018</p>
                    <p>Hausman Lebanon</p>
                </div>
                <div className="flex-none mr-48">
                    <span className="timeline-dot"></span>
                    <div className="timeline-image">
                        <img className="mb-2 h-full w-full object-cover" src="/images/timeline/hungary.jpg" />
                    </div>
                    <p className="font-bold">2020</p>
                    <p>MAN Enterprise Hungary</p>
                </div>
            </div>

            <div className="mt-12 timeline-line">
                <hr className="w-full" />
            </div>

            <div className="flex ml-4 mt-12 bottom-timeline">
                <div className="flex-none mr-32">
                    <span className="timeline-dot"></span>
                    <div className="timeline-image">
                        <img className="mb-2 w-32 pt-8 object-center" src="/images/logo.svg" />
                    </div>
                    <p className="font-bold">1971</p>
                    <p>Establishment of MAN Enterprise Lebanon</p>
                    <p className="text-sm">(formerly known as Michel Abi Nader Engineering Office)</p>
                </div>
                <div className="flex-none mr-48">
                    <span className="timeline-dot"></span>
                    <div className="timeline-image">
                        <img className="mb-2 h-full w-full object-cover" src="/images/timeline/qatar.jpg" />
                    </div>
                    <p className="font-bold pt-2">2002</p>
                    <p>MAN Enterprise Qatar</p>
                </div>
                <div className="flex-none mr-48">
                    <span className="timeline-dot"></span>
                     <div className="timeline-image">
                        <img className="mb-2 h-full w-full object-cover" src="/images/timeline/sudan-cropped.jpg" />
                    </div>
                    <p className="font-bold">2005</p>
                    <p>MAN Enterprise Sudan</p>
                </div>
                <div className="flex-none mr-48">
                    <span className="timeline-dot"></span>
                    <div className="timeline-image">
                        <img className="mb-2 h-full w-full object-cover" src="/images/timeline/libya.jpg" />
                    </div>
                    <p className="font-bold">2007</p>
                    <p>MAN Enterprise in Libya and Jordan</p>
                </div>
                <div className="flex-none mr-48">
                    <span className="timeline-dot"></span>
                    <div className="timeline-image">
                        <img className="mb-2 h-full w-full object-cover" src="/images/timeline/ghana.jpg" />
                    </div>
                    <p className="font-bold">2013</p>
                    <p>MAN Enterprise Ghana</p>
                </div>
                <div className="flex-none mr-48">
                    <span className="timeline-dot"></span>
                    <div className="timeline-image">
                        <img className="mb-2 h-full w-full object-cover" src="/images/timeline/limassol.jpg" />
                    </div>
                    <p className="font-bold">2017</p>
                    <p>MAN Enterprise Cyprus</p>
                </div>
                <div className="flex-none mr-48">
                    <span className="timeline-dot"></span>
                    <div className="timeline-image">
                        <img className="mb-2 h-full w-full object-cover" src="/images/timeline/hausman-timeline.png" />
                    </div>
                    <p className="font-bold">2019</p>
                    <p>Hausman Qatar</p>
                </div>
                <div className="flex-none mr-48">
                    <span className="timeline-dot"></span>
                    <div className="timeline-image">
                        <img className="mb-2 h-full w-full object-cover" src="/images/timeline/mondis-timeline.png" />
                    </div>
                    <p className="font-bold">2021</p>
                    <p>Mondis</p>
                </div>

            </div>

            {visibleNext &&
                <button ref={nextButtonRef} onClick={handleNext} className="next-slide--timeline outline-none absolute z-50 border-shadow">
                    <svg className="w-6 h-6 mx-auto" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 8l4 4m0 0l-4 4m4-4H3"></path></svg>
                </button>
            }

            {visiblePrev && 
                <button style={getPrevButtonPosition()} ref={prevButtonRef} onClick={handlePrev} className="prev-slide--timeline outline-none absolute z-50 border-shadow">
                    <svg className="w-6 h-6 mx-auto" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16l-4-4m0 0l4-4m-4 4h18"></path></svg>
                </button>
            }


        </div>
)};

const NextButton = ({onClick}) => {

    return ( 
        <button onClick={onClick} className="next-slide--timeline outline-none absolute z-50 border-shadow">
            <svg className="w-6 h-6 mx-auto" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 8l4 4m0 0l-4 4m4-4H3"></path></svg>
        </button>
    )
}


const PrevButton = ({onClick}) => {

    return ( 
        <button  onClick={onClick} className="prev-slide--timeline outline-none absolute z-50 border-shadow">
            <svg className="w-6 h-6 mx-auto" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16l-4-4m0 0l4-4m-4 4h18"></path></svg>
        </button>
    )
}


export default Timeline
